import * as React from 'react';
import {
    getAuthUser
} from '../helpers/user';
import IUser from '../_typescript/interfaces/IUser';
import {
    navigate
} from 'gatsby';
import {
    useEffect, useState
} from 'react';
import DefaultPage from '../components/layouts/DefaultPage';
import AuthenticatedContent from '../components/AuthenticatedContent/AuthenticatedContent';
import CustomButton from '../components/CustomButton/CustomButton';
import {
    createApplication, fetchApplication
} from '../api/application';
import {
    connect
} from 'react-redux';


// markup
const IndexPage = ({
    application, setApplication,
}) => {
    const user = getAuthUser() as IUser;
    const [isLoading, setIsLoading,]= useState(true);

    useEffect(
        () => {
            if (!user) {
                setApplication({
                    application: null,
                });
                navigate('/user/login-register');
            }
        },
        [user,]
    );

    useEffect(
        () => {
            if (user) {
                if (application) {
                    if ('jury' === process.env.GATSBY_PHASE) {
                        navigate('/jury/welcome');
                    } else {
                        navigate('/application');
                    }
                } else {
                    (async () => {
                        setIsLoading(true);
                        setApplication(await fetchApplication());
                        setIsLoading(false);
                    })();
                }
            }
        },
        [application,]
    );

    if (!user) {
        return null;
    }

    if(isLoading) {
        return null;
    }

    if(application) {
        return null;
    }

    return (
        <DefaultPage>
            <AuthenticatedContent/>
            <CustomButton
                testId="start-application"
                label="Start"
                onClick={async () => {
                    const result = await createApplication();
                    setApplication({
                        application: result.application,
                    });
                }}
            />
            <br/>
            <br/>
            <br/>
        </DefaultPage>
    );
};

const mapStateToProps = ({
    application,
}) => {
    return {
        application,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setApplication: ({
            application,
        }) => {
            return dispatch({
                type: 'SET_APPLICATION',
                payload: {
                    application,
                },
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IndexPage);

