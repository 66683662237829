import * as React from 'react';
import {
    Link
} from 'gatsby';

const AuthenticatedContent = () => {
    return <div
        data-test-id="authenticated-content"
        className={'grid-8--desktop grid-offset-2--desktop grid-8--tablet grid-2--mobile'}>
        <h1>Bewerbungsprozess</h1>
        <p>
            Im nächsten Schritt gelangen Sie zu unserem Bewerbungsformular. Ihre Bewerbung wird während der Bearbeitung automatisch zwischengespeichert, so dass Sie zu einem späteren Zeitpunkt jederzeit daran weiter arbeiten können.
        </p><p>
            Bitte beachten Sie, dass die Bewerbungsphase bis zum 20.05.2022 läuft und wir nur Bewerbungen  berücksichtigen können, die in diesem Zeitraum eingegangen sind.
        </p><p>
            Die Teilnahmebedingungen für den FOCUS Innovationspreis finden Sie <Link to="/teilnahmebedingungen">HIER</Link>.
        </p>
    </div>;
};

export default AuthenticatedContent;
